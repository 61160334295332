import { render, staticRenderFns } from "./lecOne.vue?vue&type=template&id=16a60094&scoped=true&"
import script from "./lecOne.vue?vue&type=script&lang=js&"
export * from "./lecOne.vue?vue&type=script&lang=js&"
import style0 from "./lecOne.vue?vue&type=style&index=0&id=16a60094&lang=scss&scoped=true&"
import style1 from "./lecOne.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16a60094",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VItem } from 'vuetify/lib/components/VItemGroup';
import { VItemGroup } from 'vuetify/lib/components/VItemGroup';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSlideGroup } from 'vuetify/lib/components/VSlideGroup';
import { VSlideItem } from 'vuetify/lib/components/VSlideGroup';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSpeedDial } from 'vuetify/lib/components/VSpeedDial';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
import { VTreeview } from 'vuetify/lib/components/VTreeview';
installComponents(component, {VAlert,VAutocomplete,VBtn,VCard,VCardActions,VCardText,VCardTitle,VCheckbox,VCol,VContainer,VDataTable,VDialog,VDivider,VIcon,VItem,VItemGroup,VList,VListItem,VListItemContent,VListItemSubtitle,VListItemTitle,VMenu,VPagination,VRow,VSlideGroup,VSlideItem,VSpacer,VSpeedDial,VTextField,VToolbar,VTooltip,VTreeview})


/* vuetify-loader */
import installDirectives from "!../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Resize from 'vuetify/lib/directives/resize'
installDirectives(component, {Resize})
