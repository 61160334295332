<template>
  <v-container class="pt-4">
    <v-row>
      <v-col cols="12" md="12">
        <v-card class="d-flex align-center rounded-lg pa-4" height="60" flat>
          <v-row>
            <v-col cols="12" md="6" sm="12">
              <v-autocomplete
                v-if="isInRole(2)"
                class="mt-6"
                @change="reCall()"
                v-model="stageSelect"
                :items="stages"
                outlined
                dense
                chips
                small-chips
                :label="$t('lecOne.selectStage')"
                multiple
                item-text="name"
                item-value="stageGuid"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-spacer></v-spacer>
          <v-btn tile color="primary" @click="back()" class="rounded-xl">
            {{ $t("back") }}
            <v-icon right> mdi-arrow-left </v-icon>
          </v-btn>
        </v-card>
      </v-col>
    </v-row>

    <v-card class="mx-auto my-2" flat>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        :label="$t('writeWhatYouSearch')"
        outlined
        dense
        class="pa-2 pt-4"
        hide-details
      >
        <template v-slot:append-outer>
          <v-card-actions style="margin-top: -9px" class="pt-0">
            <v-btn
              icon
              light
              :color="currentView == 'large' ? 'primary' : ''"
              @click="setView('large')"
            >
              <v-icon>mdi-grid-large</v-icon>
            </v-btn>
            <v-btn
              icon
              light
              :color="currentView == 'details' ? 'primary' : ''"
              @click="setView('details')"
            >
              <v-icon>mdi-view-list</v-icon>
            </v-btn>
          </v-card-actions>
        </template>
      </v-text-field>
      <v-divider></v-divider>
      <v-card-title class="pa-0 ma-0 mx-2">
        <v-icon v-if="currentFolder" color="primary" class="ma-2"
          >mdi-folder-open</v-icon
        >
        <v-icon v-else color="primary" class="ma-2">mdi-home</v-icon>
        <div v-if="currentFolder">{{ currentFolder.folderName }}</div>
        <div v-else>{{ $t("lecOne.Home") }}</div>
      </v-card-title>
      <v-divider></v-divider>

      <v-slide-group
        multiple
        show-arrows
        class="py-4"
        mandatory
        v-if="foldersFilter && foldersFilter[0]"
      >
        <v-slide-item
          v-for="(item, index) in foldersFilter"
          :key="index"
          class="mx-2"
        >
          <v-card outlined width="180">
            <v-card-text class="ma-0 pa-0 pt-1">
              <v-menu v-if="isInRole(2)">
                <template v-slot:activator="{ on: menu, attrs }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on: tooltip }">
                      <v-btn
                        icon
                        color="grey"
                        :class="
                          $vuetify.rtl
                            ? 'mt-n0 mx-n2 zin posRight'
                            : 'mt-n0 mx-n2 zin posLeft'
                        "
                        v-bind="attrs"
                        v-on="{ ...tooltip, ...menu }"
                      >
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("more") }}</span>
                  </v-tooltip>
                </template>
                <v-list>
                  <v-list-item @click="editFolder(item)">
                    <v-list-item-title>{{ $t("edit") }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="deleteFolder(item)">
                    <v-list-item-title>{{ $t("delete") }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="moveFolderPopUp(item)">
                    <v-list-item-title>{{ $t("Move") }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>

              <v-row>
                <v-col
                  lg="12"
                  md="12"
                  sm="12"
                  class="py-0 ma-0 mt-4"
                  align="center"
                >
                  <v-btn
                    width="75px"
                    height="75px"
                    icon
                    @click="OpenFolder(item.guid)"
                  >
                    <v-icon color="primary" size="70">mdi-folder</v-icon>
                  </v-btn>
                </v-col>
                <v-col
                  lg="12"
                  md="12"
                  sm="12"
                  class="py-0 ma-0 mb-2"
                  align="center"
                >
                  <v-list-item two-line>
                    <v-list-item-content class="py-0 ma-0 mb-5">
                      <v-list-item-title
                        dir="auto"
                        :title="item.folderName"
                        class="mb-1"
                        style="text-align: center; overflow: hidden !important"
                      >
                        {{ item.folderName }}
                      </v-list-item-title>
                      <v-list-item-subtitle class="caption">{{
                        item.createdDate | moment("YYYY/MM/DD - h:mm a ")
                      }}</v-list-item-subtitle>
                      <v-list-item-subtitle class="caption">{{
                        item.teacherName
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-slide-item>
      </v-slide-group>
      <v-divider></v-divider>

      <v-card-actions v-if="isInRole(2)">
        <v-checkbox
          v-if="currentView == 'large'"
          class="select-all-cards pa-0 ma-2"
          :title="$t('SelectAll')"
          :indeterminate="someNodesSelected"
          :input-value="allNodesSelected"
          @click.stop="toggleCompleteSelection"
        ></v-checkbox>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="!rowKeysSelected || rowKeysSelected.length == 0"
          :loading="sendLoad"
          outlined
          color="primary"
          class=""
          @click="getFoldersTree('files')"
        >
          {{ $t("lecOne.MoveFiles") }}
          <v-icon class="px-2">mdi-file-move-outline</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-row v-resize="onResize" v-if="lecturesFilter && lecturesFilter[0]">
      <v-col cols="12" lg="12" md="12" sm="12" v-if="currentView == 'details'">
        <v-data-table
          style="margin-bottom: 60px"
          :headers="getHeaders"
          :items="lecturesFilter"
          :search="search"
          :single-select="!isInRole(2)"
          :show-select="isInRole(2)"
          v-model="rowKeysSelected"
          selectable-key="guid"
          item-key="guid"
          @click:row="function (item, row) {
              if (!isInRole(2)) {
                row.select(true);
              }
            }
          "
        >
          <template v-slot:[`item.lectureName`]="{ item }">
            <div>
              <v-list-item two-line>
                <v-menu v-if="isInRole(2)">
                  <template v-slot:activator="{ on: menu, attrs }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on: tooltip }">
                        <v-btn
                          icon
                          color="grey"
                          v-bind="attrs"
                          v-on="{ ...tooltip, ...menu }"
                        >
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t("more") }}</span>
                    </v-tooltip>
                  </template>
                  <v-list>
                    <v-list-item @click="editLec(item)">
                      <v-list-item-title>{{ $t("edit") }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="deleteLec(item)">
                      <v-list-item-title>{{ $t("delete") }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>

                <files-icon
                  :fileType="item.filetype"
                  :fileSizeFormat="item.fileSizeFormat"
                  :showBadge="false"
                  :width="46"
                ></files-icon>

                <v-list-item-content>
                  <div class="mb-0 text-wrap">
                    {{ item.lectureName }}
                  </div>
                  <v-list-item-subtitle>
                    {{ item.subjectName }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class="caption">
                    {{ item.teacherName }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </div>
          </template>
          <template v-slot:[`item.filetype`]="{ item }">
            {{ item.filetype.toUpperCase() }}
          </template>
          <template v-slot:[`item.datex`]="{ item }">
            {{ item.datex | moment("YYYY/MM/DD - h:mm a ") }}
          </template>
          <template v-slot:[`item.downloadCount`]="{ item }">
            <v-btn
              v-if="isInRole(2)"
              text
              :title="$t('lecOne.DownloadCount') + ` (${item.downloadCount})`"
              @click="getFileDownloadPersons(item)"
            >
              <v-icon color="primary" small>mdi-download</v-icon>
              {{ item.downloadCount2 }}
            </v-btn>
            <span
              v-if="isInRole(2)"
              style="text-align: center"
              class="mx-2"
              :title="$t('lecOne.LastDownloadDate')"
            >
              <v-icon color="primary" small>mdi-progress-download</v-icon>
              {{ item.lastDownloadDate2 }}
            </span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-card-actions>
              <v-btn
                class="px-3"
                color="primary"
                outlined
                @click="openLec(item)"
              >
                {{ $t("lecOne.lecOpen") }}
                <v-icon class="px-2">mdi-file-eye-outline</v-icon>
              </v-btn>
            </v-card-actions>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-item-group :multiple="isInRole(2)" v-model="rowKeysSelected">
      <v-row
        class="mt-3"
        v-if="currentView == 'large' && lecturesFilter && lecturesFilter[0]"
      >
        <v-col
          cols="12"
          x-lg="3"
          lg="3"
          md="4"
          sm="12"
          x-sm="12"
          v-for="(item, index) in visiblePages"
          :key="index"
          :search="search"
        >
          <v-item v-slot="{ active, toggle }" :value="item">
            <v-card
              flat
              class="pa-4"
              :color="
                active ? ($vuetify.theme.dark ? '#505050' : '#e5e5e5') : ''
              "
              @click="toggle"
            >
              <v-row class="ma-0 pa-0">
                <v-col cols="12" md="6" sm="12" class="ma-0 pa-0">
                  <div
                    :class="
                      $vuetify.rtl
                        ? 'rounded-sm rounded-br-xl posDate pa-2 primary white--text'
                        : 'rounded-sm rounded-bl-xl posDate2 pa-2 primary white--text'
                    "
                  >
                    {{ item.datex | moment("YYYY/MM/DD - h:mm a ") }}
                  </div>
                </v-col>
                <v-spacer></v-spacer>
                <v-menu v-if="isInRole(2)">
                  <template v-slot:activator="{ on: menu, attrs }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on: tooltip }">
                        <v-btn
                          icon
                          color="grey"
                          :class="
                            $vuetify.rtl
                              ? 'mt-n3 mx-n2 zin posRight'
                              : 'mt-n3 mx-n2 zin posLeft'
                          "
                          v-bind="attrs"
                          v-on="{ ...tooltip, ...menu }"
                        >
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t("more") }}</span>
                    </v-tooltip>
                  </template>
                  <v-list>
                    <v-list-item @click="editLec(item)">
                      <v-list-item-title>{{ $t("edit") }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="deleteLec(item)">
                      <v-list-item-title>{{ $t("delete") }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-row>

              <v-row class="text-center py-4">
                <v-col class="mt-8">
                  <files-icon
                    :fileType="item.filetype"
                    :fileSizeFormat="item.fileSizeFormat"
                    :showBadge="true"
                    :width="100"
                  ></files-icon>
                </v-col>
              </v-row>
              <v-row class="">
                <v-col>
                  <v-list-item three-line>
                    <v-list-item-content>
                      <v-list-item-title :title="item.lectureName" class="mb-1">
                        {{ item.lectureName }}
                      </v-list-item-title>
                      <v-list-item-subtitle>{{
                        ` ${item.subjectName}`
                      }}</v-list-item-subtitle>
                      <v-list-item-subtitle>{{
                        `${item.stageName} - ${item.studyName}`
                      }}</v-list-item-subtitle>
                      <v-list-item-subtitle>{{
                        `${item.teacherName}`
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
              <v-card-actions>
                <!-- <v-btn
              outlined
              @click="insLec(item, index)"
              class="teal"
              dark
              v-if="
                !item.downloadProgress || item.downloadProgress <= 0 || item.downloadProgress >= 100
              "
              >{{ $t("lecOne.lecDownlaod") }}
              <v-icon class="px-2">mdi-file-download-outline</v-icon>
            </v-btn>

            <v-progress-linear
              v-if="item.downloadProgress > 0 && item.downloadProgress < 100"
              v-model="item.downloadProgress"
              color="secondary"
              height="35"
              :style="$vuetify.rtl ? 'margin-left: 5px;' : 'margin-right: 5px;'"
            >
              <template v-slot:default="{ value }">
                <strong>{{ Math.ceil(value) }}%</strong>
              </template>
            </v-progress-linear> -->

                <v-btn
                  outlined
                  @click="openLec(item)"
                  color="primary"
                  dark
                  :width="100"
                  >{{ $t("lecOne.lecOpen") }}
                  <v-icon class="px-2">mdi-file-eye-outline</v-icon>
                </v-btn>

                <v-spacer></v-spacer>

                <v-btn
                  v-if="isInRole(2)"
                  text
                  :title="
                    $t('lecOne.DownloadCount') + ` (${item.downloadCount})`
                  "
                  @click="getFileDownloadPersons(item)"
                >
                  <v-icon color="primary" small>mdi-download</v-icon>
                  {{ item.downloadCount2 }}
                </v-btn>
                <!--
            <span
              v-if="isInRole(2)"
              style="text-align:center"
              class="mx-1"
              :title="$t('lecOne.DownloadCount') + ` (${item.downloadCount})`"
            >
              <v-icon color="primary" small>mdi-download</v-icon>
              {{ item.downloadCount2 }}
            </span> -->
                <span
                  v-if="isInRole(2)"
                  style="text-align: center"
                  class="mx-1"
                  :title="$t('lecOne.LastDownloadDate')"
                >
                  <v-icon color="primary" small>mdi-progress-download</v-icon>
                  {{ item.lastDownloadDate2 }}
                </span>
              </v-card-actions>
            </v-card>
          </v-item>
        </v-col>
        <v-col cols="12" md="12">
          <v-pagination
            v-if="lectures[0]"
            v-model="page"
            :length="Math.ceil(lecturesFilter.length / perPage)"
            total-visible="15"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-item-group>

    <v-row v-if="!overlay && !lecturesFilter[0]">
      <v-col cols="12" md="12" class="text-center mt-10">
        <img src="../../assets/folder.svg" width="150px" />
        <h3>{{ $t("lecOne.ThereAreNoLectures") }}</h3>
      </v-col>
    </v-row>

    <v-speed-dial
      v-if="isInRole(2)"
      v-model="fab"
      fixed
      bottom
      :left="$vuetify.rtl"
      :right="!$vuetify.rtl"
      :class="$store.state.drawerMessage ? 'ml-16' : ''"
      transition="slide-y-reverse-transition"
    >
      <template v-slot:activator>
        <v-btn v-model="fab" color="secondary" fab large elevation="6">
          <v-icon v-if="fab"> mdi-close </v-icon>
          <v-icon v-else> mdi-plus </v-icon>
        </v-btn>
      </template>
      <v-btn
        fab
        dark
        :title="$t('lecOne.AddFolder')"
        color="amber"
        @click="openDiaAddFolder"
      >
        <v-icon>mdi-folder</v-icon>
      </v-btn>
      <v-btn
        fab
        dark
        :title="$t('lecOne.AddALecture')"
        color="indigo"
        @click="openDia"
      >
        <v-icon>mdi-file</v-icon>
      </v-btn>
    </v-speed-dial>

    <v-dialog
      v-if="isInRole(2)"
      transition="dialog-bottom-transition"
      persistent
      max-width="600"
      v-model="dialog"
    >
      <v-card>
        <v-toolbar color="primary darken-1" dark>{{
          $t("lecOne.AddALecture")
        }}</v-toolbar>
        <v-card-text class="mt-4">
          <v-text-field
            :label="$t('lecOne.TitleOfTheLecture')"
            :placeholder="$t('lecOne.WriteTheTitleOfTheLecture')"
            outlined
            :rules="[required]"
            v-model="lecName"
          ></v-text-field>
          <v-autocomplete
            v-model="stageSelect"
            :items="stages"
            outlined
            chips
            small-chips
            :label="$t('lecOne.selectStage')"
            multiple
            item-text="name"
            item-value="stageGuid"
            :rules="[required]"
          ></v-autocomplete>
          <div class="fileUpload" eager>
            <DxFileUploader
              :allowed-file-extensions="allowedFileExtensions"
              :selectButtonText="$t('lecOne.ChooseTheFile')"
              :labelText="$t('lecOne.ChooseTheFileYouWantToUpload')"
              [multiple]="false"
              :chunk-size="5000000"
              :max-file-size="209715200"
              name="FileUpload"
              :accept="allowedFileExtensions.join(',')"
              uploadMode="instantly"
              uploadMethod="POST"
              :uploadHeaders="authHeaders"
              :upload-url="`${UploaderUrl}Lectures`"
              @upload-started="() => (chunks = [])"
              @progress="onUploadProgress($event)"
              @uploaded="onUploaded($event)"
              ref="FileUpload"
            />
            <v-alert dense text type="info" color="primary">
              <span class="note"
                >{{ $t("lecOne.AllowedFiles") }} :
                <span
                  >jpg, jpeg, gif, png, bmp, mp4, wmv, flv, avi, webm, weba wav,
                  mp3, aac, pdf, doc, docx, xls, xlsx, ppt, pptx, txt, zip,
                  rar</span
                >.</span
              >
              <span class="note"
                >{{ $t("lecOne.MaximumFileSize") }} : <span>200MB</span></span
              >
            </v-alert>
          </div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-spacer></v-spacer>
          <v-btn color="error darken-1" text @click="closeDialog">
            {{ $t("close") }}
          </v-btn>
          <v-btn
            :loading="sendLoad"
            color="primary darken-1"
            @click="uploadData()"
            :disabled="
              !fileURL || !lecName || !stageSelect || stageSelect.length == 0
            "
          >
            {{ $t("save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="isInRole(2)"
      transition="dialog-bottom-transition"
      persistent
      max-width="600"
      v-model="dialogAddFolder"
    >
      <v-card>
        <v-toolbar color="primary darken-1" dark
          >{{ $t("lecOne.AddFolder") }}
        </v-toolbar>
        <v-card-text class="mt-4">
          <v-text-field
            :label="$t('lecOne.FolderName')"
            :placeholder="$t('lecOne.WriteTheFolderName')"
            outlined
            v-model="lecName"
            :rules="[required]"
          ></v-text-field>
          <v-autocomplete
            v-model="stageSelect"
            :items="stages"
            outlined
            chips
            small-chips
            :label="$t('lecOne.selectStage')"
            multiple
            item-text="name"
            item-value="stageGuid"
            :rules="[required]"
          ></v-autocomplete>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-spacer></v-spacer>
          <v-btn color="error darken-1" text @click="closeDialogFolder">
            {{ $t("close") }}
          </v-btn>
          <v-btn
            :loading="sendLoad"
            color="primary darken-1"
            @click="AddFolder()"
            :disabled="!lecName || !stageSelect || stageSelect.length == 0"
          >
            {{ $t("save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="isInRole(2)"
      transition="dialog-bottom-transition"
      persistent
      max-width="600"
      v-model="dialogFolderEdit"
    >
      <v-card>
        <v-toolbar color="primary darken-1" dark>{{
          $t("lecOne.RenameFolder")
        }}</v-toolbar>
        <v-card-text class="mt-4">
          <v-text-field
            :label="$t('lecOne.FolderName')"
            :placeholder="$t('lecOne.WriteTheFolderName')"
            outlined
            v-model="lecName"
            :rules="[required]"
          ></v-text-field>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-spacer></v-spacer>
          <v-btn color="error darken-1" text @click="dialogFolderEdit = false">
            {{ $t("close") }}
          </v-btn>
          <v-btn
            :loading="sendLoad"
            color="primary darken-1"
            @click="editFolderSave()"
            :disabled="!lecName"
          >
            {{ $t("save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      transition="dialog-bottom-transition"
      persistent
      max-width="600"
      v-model="dialogEdit"
      v-if="isInRole(2)"
    >
      <v-card>
        <v-toolbar color="primary darken-1" dark>{{
          $t("lecOne.ModifyTheLectureTitle")
        }}</v-toolbar>
        <v-card-text class="mt-4">
          <v-text-field
            :label="$t('lecOne.TitleOfTheLecture')"
            :placeholder="$t('lecOne.WriteTheTitleOfTheLecture')"
            :rules="[required]"
            outlined
            v-model="nameEdit"
          ></v-text-field>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-spacer></v-spacer>
          <v-btn color="error darken-1" text @click="dialogEdit = false">
            {{ $t("close") }}
          </v-btn>
          <v-btn
            :loading="sendLoad"
            color="primary darken-1"
            @click="sendEdit()"
            :disabled="!nameEdit"
          >
            {{ $t("save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogDownloadPersons"
      max-width="600px"
      v-if="isInRole(2)"
    >
      <v-card>
        <v-toolbar color="primary darken-1" dark
          >{{ $t("lecOne.DownloadCount") }} ({{ downloadCountLec }})</v-toolbar
        >
        <v-card-title>
          <v-text-field
            v-model="searchPersons"
            append-icon="mdi-magnify"
            :label="$t('search')"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="downloadPersonsHeaders"
          :search="searchPersons"
          :items="downloadPersons"
          :loading="dialogDownloadPersonsLoading"
        >
          <template v-slot:[`item.downloadDate`]="{ item }">
            <span>
              <v-icon color="primary" small>mdi-progress-download</v-icon>
              {{ item.downloadDate2 }}
            </span>
          </template>
        </v-data-table>
        <v-card-actions class="justify-end">
          <v-spacer></v-spacer>
          <v-btn
            color="error darken-1"
            text
            @click="dialogDownloadPersons = false"
          >
            {{ $t("close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="moveFileDialog"
      max-width="600px"
      eager
      v-if="isInRole(2)"
    >
      <v-card>
        <v-toolbar color="primary darken-1" dark v-if="rowKeysSelected"
          >{{
            moveFolder ? $t("lecOne.MoveFolder") : $t("lecOne.MoveFiles")
          }}
          ({{
            moveFolder ? moveFolderInfo.folderName : rowKeysSelected.length
          }})
        </v-toolbar>
        <div style="max-height: 430px; overflow: auto">
          <v-treeview
            ref="treeReference"
            :items="foldersTree"
            activatable
            item-text="folderName"
            item-key="guid"
            item-children="children"
            selection-type="independent"
            @update:active="function (val) {
                selectedFolderTree = val[0];
              }
            "
          >
            <template v-slot:prepend="{ item }">
              <v-icon
                color="primary"
                v-if="item.guid == '00000000-0000-0000-0000-000000000000'"
              >
                mdi-home
              </v-icon>
              <v-icon v-else> mdi-folder </v-icon>
            </template>
            <template v-slot:label="{ item }">
              <v-row>
                <v-col align-self="center">
                  {{ item.folderName }}
                </v-col>
              </v-row>
            </template>
          </v-treeview>
        </div>
        <v-card-actions class="justify-end">
          <v-spacer></v-spacer>
          <v-btn color="error darken-1" text @click="moveFileDialog = false">
            {{ $t("close") }}
          </v-btn>
          <v-btn
            :loading="sendLoad"
            color="primary darken-1"
            @click="moveFolder == true ? moveFolderFunc() : moveFiles()"
            :disabled="!selectedFolderTree"
          >
            {{ $t("Move") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import DxFileUploader from "devextreme-vue/file-uploader";
import { mapState } from "vuex";
import moment from "moment";
import FilesIcon from "../../components/filesIcon.vue";

export default {
  components: {
    DxFileUploader,
    FilesIcon,
  },
  props: ["id", "folderId", "stagesId"],
  data() {
    return {
      required: (value) => !!value || this.$t("ThisFieldIsRequired"),
      search: "",
      fab: false,
      active: [],
      rowKeysSelected: [],
      searchPersons: "",
      downloadPersons: [],
      downloadCountLec: 0,
      dialogDownloadPersons: false,
      dialogDownloadPersonsLoading: false,
      items: [{ title: this.$t("edit") }, { title: this.$t("delete") }],
      allowedFileExtensions: [
        ".jpg",
        ".jpeg",
        ".gif",
        ".png",
        ".bmp",
        ".mp4",
        ".wmv",
        ".flv",
        ".avi",
        ".webm",
        ".weba",
        ".wav",
        ".mp3",
        ".aac",
        ".pdf",
        ".doc",
        ".docx",
        ".xls",
        ".xlsx",
        ".ppt",
        ".pptx",
        ".txt",
        ".zip",
        ".rar",
      ],
      lecName: null,
      // uploadURL: process.env.VUE_APP_UPLOAD,
      authHeaders: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      chunks: [],
      dialog: false,
      overlay: false,
      lectures: [],
      LecTech: [],
      departments: [],
      stages: [],
      stageSelect: [],
      values: [],
      fileURL: null,
      sendLoad: false,
      dialogEdit: false,
      dialogFolderEdit: false,
      guidEdit: null,
      nameEdit: null,
      subjectName: "",
      headers: [
        {
          text: this.$t("drawer.lectures"),
          value: "lectureName",
          show: true,
        },
        {
          text: this.$t("lecOne.FileSize"),
          value: "fileSizeFormat",
          show: true,
        },
        {
          text: this.$t("fileType"),
          value: "filetype",
          show: true,
        },
        {
          text: this.$t("lecOne.Date"),
          value: "datex",
          show: true,
        },
        {
          text: this.$t("lecOne.DownloadCount"),
          value: "downloadCount",
          show: this.isInRole(2),
        },
        {
          text: "",
          value: "actions",
          sortable: false,
          show: true,
        },
      ],
      downloadPersonsHeaders: [
        {
          text: this.$t("name"),
          value: "personFullName",
          show: true,
        },
        {
          text: " ",
          value: "downloadDate",
          show: true,
        },
      ],
      viewTypes: [
        {
          text: "Large",
          value: "large",
        },
        {
          text: "Details",
          value: "details",
        },
      ],
      currentView: "",
      folders: [],
      folderGuid: null,
      dialogAddFolder: false,
      currentFolder: null,
      folderGuidEdit: null,
      moveFileDialog: false,
      foldersTree: [],
      foldersTreeList: [],
      selectedFolderTree: null,
      moveFolder: false,
      moveFolderInfo: null,
      page: 1,
      perPage: 8,
    };
  },
  computed: {
    foldersFilter() {
      let dataList = [];
      dataList = this.folders.filter((item) => this.search
          .toLowerCase()
          .split(" ")
          .every(
            (v) => item.folderName.toLowerCase().includes(v) ||
              item.createdDate.toLowerCase().includes(v)
          ));

      return dataList;
    },
    visiblePages() {
      return this.lecturesFilter.slice(
        (this.page - 1) * this.perPage,
        this.page * this.perPage
      );
    },
    lecturesFilter() {
      let dataList = [];
      dataList = this.lectures.filter((item) => this.search
          .toLowerCase()
          .split(" ")
          .every(
            (v) => item.lectureName.toLowerCase().includes(v) ||
              item.stageName.toLowerCase().includes(v) ||
              item.studyName.toLowerCase().includes(v) ||
              item.datex.toLowerCase().includes(v)
          ));

      return dataList;
    },
    noNodesSelected() {
      return !this.rowKeysSelected || this.rowKeysSelected.length === 0;
    },
    someNodesSelected() {
      return (
        this.rowKeysSelected &&
        this.rowKeysSelected.length > 0 &&
        !this.allNodesSelected
      );
    },
    allNodesSelected() {
      return (
        this.rowKeysSelected &&
        this.rowKeysSelected.length > 0 &&
        this.rowKeysSelected.length === this.lecturesFilter.length
      );
    },
    getHeaders() {
      return this.headers.filter((m) => m.show);
    },
    ...mapState(["UploaderUrl"]),
  },
  methods: {
    toggleCompleteSelection() {
      if (this.noNodesSelected || this.someNodesSelected) {
        this.rowKeysSelected = this.lecturesFilter;
      } else {
        this.rowKeysSelected = [];
      }
    },
    openDia() {
      this.clear();
      this.rowKeysSelected = [];
      this.dialog = !this.dialog;
      if (this.$refs.FileUpload) {
        this.$refs.FileUpload.instance.reset();
      }
    },
    openDiaAddFolder() {
      this.clear();
      this.rowKeysSelected = [];
      this.dialogAddFolder = !this.dialogAddFolder;
    },
    onResize() {
      if (window.innerWidth <= 600) {
        this.currentView = "large";
      } else {
        this.currentView =
          localStorage.getItem("lectureView") != "large" &&
          localStorage.getItem("lectureView") != "details"
            ? "large"
            : localStorage.getItem("lectureView");
      }
    },
    setView(view) {
      this.rowKeysSelected = [];

      this.currentView = view;
      localStorage.setItem("lectureView", view);
    },
    sendEdit() {
      if (!this.isInRole(2)) return;

      const data = {
        Guid: this.guidEdit,
        LectureName: this.nameEdit,
      };
      this.axios
        .put("Lectures/RenameLecture", data)
        .then((res) => {
          this.reCall();
          this.$swal.fire({
            title: this.$t("operationAccomplishedSuccessfully"),
            text: "",
            icon: "success",
            confirmButtonText: this.$t("close"),
            toast: true,
            position: "bottom-start",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });
          this.dialogEdit = false;
        })
        .catch(() => {
          this.$swal.fire({
            title: this.$t("AnErrorOccurredDuringTheProcess"),
            text: "",
            icon: "error",
            confirmButtonText: this.$t("close"),
          });
        });
    },
    editLec(item) {
      // console.log(item);
      this.guidEdit = item.guid;
      this.nameEdit = item.lectureName;
      this.dialogEdit = true;
    },

    deleteLec(item) {
      if (!this.isInRole(2)) return;

      this.$swal
        .fire({
          title: this.$t("AreYouSureAboutTheOperation"),
          text: "",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: this.$t("yes"),
          cancelButtonText: this.$t("no"),
        })
        .then((result) => {
          if (result.value) {
            this.axios
              .delete(`Lectures/DeleteLecture?Guid=${item.guid}`)
              .then((res) => {
                this.reCall();
                this.$swal.fire({
                  title: this.$t("operationAccomplishedSuccessfully"),
                  text: "",
                  icon: "success",
                  confirmButtonText: this.$t("close"),
                  toast: true,
                  position: "bottom-start",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                  didOpen: (toast) => {
                    toast.addEventListener("mouseenter", this.$swal.stopTimer);
                    toast.addEventListener(
                      "mouseleave",
                      this.$swal.resumeTimer
                    );
                  },
                });
              })
              .catch(() => {
                this.$swal.fire({
                  title: this.$t("AnErrorOccurredDuringTheProcess"),
                  text: "",
                  icon: "error",
                  confirmButtonText: this.$t("close"),
                });
              });
          }
        });
    },
    deleteFolder(item) {
      if (!this.isInRole(2)) return;

      this.$swal
        .fire({
          title: this.$t("AreYouSureAboutTheOperation"),
          text: "",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: this.$t("yes"),
          cancelButtonText: this.$t("no"),
        })
        .then((result) => {
          if (result.value) {
            this.axios
              .delete(
                `Folders/DeleteFolder?FolderGuid=${item.guid}&FolderType=lecture`
              )
              .then((res) => {
                if (res.data.statusCode == 1) {
                  this.reCall();
                  this.$swal.fire({
                    title: this.$t("operationAccomplishedSuccessfully"),
                    text: "",
                    icon: "success",
                    confirmButtonText: this.$t("close"),
                    toast: true,
                    position: "bottom-start",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                      toast.addEventListener(
                        "mouseenter",
                        this.$swal.stopTimer
                      );
                      toast.addEventListener(
                        "mouseleave",
                        this.$swal.resumeTimer
                      );
                    },
                  });
                } else if (res.data.statusCode == 2) {
                  this.$swal.fire({
                    title: this.$t("errors.CannotDeleteFolderContainsFiles"),
                    text: "",
                    icon: "error",
                    confirmButtonText: this.$t("close"),
                  });
                } else if (res.data.statusCode == 3) {
                  this.$swal.fire({
                    title: this.$t(
                      "errors.CannotDeleteFolderContainsChildFolders"
                    ),
                    text: "",
                    icon: "error",
                    confirmButtonText: this.$t("close"),
                  });
                } else {
                  this.$swal.fire({
                    title: this.$t(
                      "errors.AnErrorOccurredTheOperationDidNotCompleteSuccessfully"
                    ),
                    text: "",
                    icon: "error",
                    confirmButtonText: this.$t("close"),
                  });
                }
              })
              .catch(() => {
                this.$swal.fire({
                  title: this.$t("AnErrorOccurredDuringTheProcess"),
                  text: "",
                  icon: "error",
                  confirmButtonText: this.$t("close"),
                });
              });
          }
        });
    },
    editFolder(item) {
      this.lecName = item.folderName;
      this.folderGuidEdit = item.guid;
      this.folderEdit = item;
      this.dialogFolderEdit = true;
    },
    moveFolderPopUp(item) {
      this.folderGuidEdit = item.guid;
      this.moveFolderInfo = item;
      this.moveFolder = true;
      this.getFoldersTree("folders");
      this.moveFileDialog = true;
    },
    editFolderSave() {
      if (!this.isInRole(2)) return;

      const data = {
        Guid: this.folderGuidEdit,
        FolderName: this.lecName,
        FolderType: "lectrue",
      };
      this.axios
        .put("Folders/RenameFolder", data)
        .then((res) => {
          this.reCall();
          this.$swal.fire({
            title: this.$t("operationAccomplishedSuccessfully"),
            text: "",
            icon: "success",
            confirmButtonText: this.$t("close"),
            toast: true,
            position: "bottom-start",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });
          this.dialogFolderEdit = false;
        })
        .catch(() => {
          this.$swal.fire({
            title: this.$t("AnErrorOccurredDuringTheProcess"),
            text: "",
            icon: "error",
            confirmButtonText: this.$t("close"),
          });
        });
    },
    closeDialog() {
      this.dialog = false;
      this.reCall();
    },
    closeDialogFolder() {
      this.dialogAddFolder = false;
      this.reCall();
    },
    clear() {
      this.lecName = null;
      // this.stageSelect = [];
      this.fileURL = null;
    },
    OpenFolder(guid) {
      let stages = "";

      for (let i = 0; i < this.stageSelect.length; i++) {
        stages = `${stages}&stagesId=${this.stageSelect[i]}`;
      }

      this.$router.push({
        path: `/lecOne/${this.id}/${guid}?${stages}`,
        params: {
          id: this.id,
          folderId: guid,
        },
      });
    },
    uploadData() {
      if (!this.isInRole(2)) return;

      if (this.stageSelect[0] && this.id && this.lecName) {
        if (
          this.folderId == "undefined" ||
          this.folderId == null ||
          !this.folderId
        ) {
          this.folderGuid = "00000000-0000-0000-0000-000000000000";
        } else {
          this.folderGuid = this.folderId;
        }

        this.sendLoad = true;
        const data = {
          StagesGuid: this.stageSelect,
          SubjectGuid: this.id,
          LectureName: this.lecName,
          FileName: this.fileURL.data.fileName,
          FolderGuid: this.folderGuid,
          FolderType: "lecture",
        };
        // console.log(data);
        this.axios
          .post("Lectures/AddLecture", data)
          .then((res) => {
            this.reCall();
            this.fileURL = null;
            this.$swal.fire({
              title: this.$t("operationAccomplishedSuccessfully"),
              text: "",
              icon: "success",
              confirmButtonText: this.$t("close"),
              toast: true,
              position: "bottom-start",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              },
            });
            this.clear();
            this.dialog = false;

            // console.log(res);
          })
          .catch(() => {
            this.$swal.fire({
              title: this.$t("AnErrorOccurredDuringTheProcess"),
              text: "",
              icon: "error",
              confirmButtonText: this.$t("close"),
            });
          })
          .finally(() => {
            this.sendLoad = false;
          });
      } else {
        this.$swal.fire({
          title: this.$t("BeSureToEnterTheInformation"),
          text: "",
          icon: "error",
          confirmButtonText: this.$t("close"),
        });
      }
    },
    AddFolder() {
      if (!this.isInRole(2)) return;

      if (this.stageSelect[0] && this.id && this.lecName) {
        this.sendLoad = true;

        if (
          this.folderId == "undefined" ||
          this.folderId == null ||
          !this.folderId
        ) {
          this.folderGuid = "00000000-0000-0000-0000-000000000000";
        } else {
          this.folderGuid = this.folderId;
        }

        const data = {
          StagesGuid: this.stageSelect,
          SubjectGuid: this.id,
          FolderName: this.lecName,
          ParentGuid: this.folderGuid,
          FilesGuid: [],
          FolderType: "lecture",
        };
        // console.log(data);
        this.axios
          .post("Folders/AddFolder", data)
          .then((res) => {
            this.reCall();
            this.fileURL = null;
            this.$swal.fire({
              title: this.$t("operationAccomplishedSuccessfully"),
              text: "",
              icon: "success",
              confirmButtonText: this.$t("close"),
              toast: true,
              position: "bottom-start",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              },
            });
            this.clear();
            this.dialogAddFolder = false;

            // console.log(res);
          })
          .catch(() => {
            this.$swal.fire({
              title: this.$t("AnErrorOccurredDuringTheProcess"),
              text: "",
              icon: "error",
              confirmButtonText: this.$t("close"),
            });
          })
          .finally(() => {
            this.sendLoad = false;
          });
      } else {
        this.$swal.fire({
          title: this.$t("BeSureToEnterTheInformation"),
          text: "",
          icon: "error",
          confirmButtonText: this.$t("close"),
        });
      }
    },
    onUploaded(e) {
      this.fileURL = JSON.parse(e.request.responseText);
      // console.log(this.fileURL.data.fileName);
    },
    getValueInKb(value) {
      return `${(value / 1024).toFixed(0)}kb`;
    },
    onUploadProgress(e) {
      // console.log(e);
      this.chunks.push({
        segmentSize: e.segmentSize,
        bytesLoaded: e.bytesLoaded,
        bytesTotal: e.bytesTotal,
      });
    },
    reCall() {
      // console.log(this.values);
      this.upDataLecTeacher();
    },
    get_url_extension(url) {
      return url.split(/[#?]/)[0].split(".").pop().trim().toLowerCase();
    },
    openLec(item) {
      this.axios
        .post(`Uploader/Download`, {
          fileUrl: item.fileUrl,
        })
        .then((res) => {
          window.open(res.data.data.url, "_blank");
        })
        .catch((err) => {})
        .finally(() => {
          this.overlay = false;
        });

      // window.open(item.fileUrl, "_blank");
    },
    getFileDownloadPersons(item) {
      this.dialogDownloadPersonsLoading = true;
      this.axios
        .get(`Lectures/FileDownloadPersons?fileUrl=${item.fileUrl}`)
        .then((res) => {
          this.downloadPersons = res.data.data;
          for (let i = 0; i < this.downloadPersons.length; i++) {
            this.downloadPersons[i].downloadDate2 = moment(
              this.downloadPersons[i].downloadDate
            ).fromNow();
          }
          this.downloadCountLec = this.downloadPersons.length;
          this.dialogDownloadPersons = true;
        })
        .catch((err) => {})
        .finally(() => {
          this.overlay = false;
          this.dialogDownloadPersonsLoading = false;
        });
    },
    moveFiles() {
      this.sendLoad = true;
      const data = {
        FolderGuid: this.selectedFolderTree,
        FilesGuid: this.rowKeysSelected.map((m) => m.guid),
        FolderType: "lecture",
      };

      this.axios
        .post("Folders/MoveFiles", data)
        .then((res) => {
          this.reCall();
          this.fileURL = null;
          this.$swal.fire({
            title: this.$t("operationAccomplishedSuccessfully"),
            text: "",
            icon: "success",
            confirmButtonText: this.$t("close"),
            toast: true,
            position: "bottom-start",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });
          this.rowKeysSelected = [];
          if (this.currentView == "large") {
            this.$forceUpdate();
          }
          this.selectedFolderTree = null;
          this.moveFileDialog = false;

          // console.log(res);
        })
        .catch(() => {
          this.$swal.fire({
            title: this.$t("AnErrorOccurredDuringTheProcess"),
            text: "",
            icon: "error",
            confirmButtonText: this.$t("close"),
          });
        })
        .finally(() => {
          this.sendLoad = false;
        });
    },
    moveFolderFunc() {
      this.sendLoad = true;
      const data = {
        MoveToFolderGuid: this.selectedFolderTree,
        FolderGuid: this.folderGuidEdit,
        FolderType: "lecture",
      };

      this.axios
        .post("Folders/MoveFolder", data)
        .then((res) => {
          if (res.data.statusCode == 1) {
            this.reCall();
            this.$swal.fire({
              title: this.$t("operationAccomplishedSuccessfully"),
              text: "",
              icon: "success",
              confirmButtonText: this.$t("close"),
              toast: true,
              position: "bottom-start",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              },
            });

            this.selectedFolderTree = null;
            this.folderGuidEdit = null;
            this.moveFileDialog = false;
          } else if (res.data.statusCode == 2) {
            this.$swal.fire({
              title: this.$t("errors.FolderCannotParentItself"),
              text: "",
              icon: "error",
              confirmButtonText: this.$t("close"),
            });
          } else if (res.data.statusCode == 3) {
            this.$swal.fire({
              title: this.$t("errors.FolderCannotChildHisChildren"),
              text: "",
              icon: "error",
              confirmButtonText: this.$t("close"),
            });
          } else {
            this.$swal.fire({
              title: this.$t(
                "errors.AnErrorOccurredTheOperationDidNotCompleteSuccessfully"
              ),
              text: "",
              icon: "error",
              confirmButtonText: this.$t("close"),
            });
          }
        })
        .catch(() => {
          this.$swal.fire({
            title: this.$t("AnErrorOccurredDuringTheProcess"),
            text: "",
            icon: "error",
            confirmButtonText: this.$t("close"),
          });
        })
        .finally(() => {
          this.sendLoad = false;
        });
    },
    getFoldersTree(moveType) {
      let stages = "";
      // console.log(this.$refs);
      this.selectedFolderTree = null;
      for (let i = 0; i < this.stageSelect.length; i++) {
        stages = `${stages}&StagesGuid=${this.stageSelect[i]}`;
      }
      this.foldersTreeList = [];
      this.foldersTree = [];
      this.overlay = true;
      this.axios
        .get(
          `Folders/GetFoldersTree?SubjectGuid=${this.id}${stages}&FolderType=lecture&MoveType=${moveType}&FolderGuid=${this.folderGuidEdit}`
        )
        .then((response) => {
          const general = {
            guid: "00000000-0000-0000-0000-000000000000",
            folderName: this.$t("lecOne.Home"),
          };

          this.foldersTreeList.push(general);
          this.foldersTree.push(general);

          for (let i = 0; i < response.data.data.length; i++) {
            var tree = response.data.data[i];
            const children = response.data.data.filter((f) => f.parentGuid == tree.guid);
            if (children.length > 0) {
              children.forEach((child) => {
                const childNode = { ...tree, item: child, vnode: null };
                this.$refs.treeReference.nodes[child.guid] = childNode;
              });
              tree.children = children;
            }
            this.foldersTreeList.push(tree);
            if (
              response.data.data[i].parentGuid ==
              "00000000-0000-0000-0000-000000000000"
            ) {
              this.foldersTree.push(tree);
            }
          }
          if (moveType == "files") {
            this.moveFolder = false;
          } else {
            this.moveFolder = true;
          }

          this.moveFileDialog = true;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.overlay = false;
        });
    },
    // insLec(item, index) {
    //   this.axios({
    //     url: `${item.fileUrl}`,
    //     method: "GET",
    //     responseType: "arraybuffer",
    //     onDownloadProgress: progressEvent => {
    //       const total = parseFloat(progressEvent.total);
    //       const current = progressEvent.loaded;
    //       let percentCompleted = Math.floor((current / total) * 100);
    //       this.lectures[index].downloadProgress = percentCompleted;
    //       this.$forceUpdate();
    //     }
    //   })
    //     .then(response => {
    //       const blob = new Blob([response.data], {
    //         type: response.headers["content-type"]
    //       });
    //       const link = document.createElement("a");
    //       link.href = URL.createObjectURL(blob);
    //       link.download =
    //         item.lectureName +
    //         "." +
    //         item.fileUrl
    //           .split(/[#?]/)[0]
    //           .split(".")
    //           .pop()
    //           .trim();

    //       link.click();
    //        URL.revokeObjectURL(link.href);
    //     })
    //     .catch(() => {
    //       this.$swal.fire({
    //         title: this.$t("TheFileDoesNotExist"),
    //         text: "",
    //         icon: "error",
    //         confirmButtonText: this.$t("close")
    //       });
    //     });
    // },
    getLecTech() {
      if (this.isInRole(2)) {
        this.overlay = true;
        this.axios
          .get("Subjects/Get")
          .then((res) => {
            this.LecTech = res.data.data.subjects;
            for (let i = 0; i < this.LecTech.length; i++) {
              if (this.LecTech[i].subjectGuid == this.id) {
                this.departments = this.LecTech[i].departments;
                this.stages = this.LecTech[i].departments[0].stages;
              }
              // console.log(this.stages);
            }
            for (let i = 0; i < this.stages.length; i++) {
              this.stages[i].name =
                `${this.stages[i].stageName} - ${this.stages[i].studyName}`;
            }
          })
          .catch((err) => {})
          .finally(() => {
            this.overlay = false;
          });
      }
    },
    upDataLecTeacher() {
      if (!this.isInRole(2)) return;

      let stages = "";

      for (let i = 0; i < this.stageSelect.length; i++) {
        stages = `${stages}&StagesGuid=${this.stageSelect[i]}`;
      }
      if (
        this.folderId == "undefined" ||
        this.folderId == null ||
        !this.folderId
      ) {
        this.folderGuid = "00000000-0000-0000-0000-000000000000";
      } else {
        this.folderGuid = this.folderId;
      }
      this.axios
        .get(
          `Lectures/Get?SubjectGuid=${this.id}&FolderGuid=${this.folderGuid}&GroupByFolder=true${stages}`
        )
        .then((res) => {
          if (res.data.data) {
            this.lectures = res.data.data.files;
            this.folders = res.data.data.folders;
            this.currentFolder = res.data.data.currentFolder;
            for (let i = 0; i < this.lectures.length; i++) {
              this.lectures[i].filetype = this.get_url_extension(
                this.lectures[i].fileNamex
              );
              this.lectures[i].downloadCount2 = this.nFormatter(
                this.lectures[i].downloadCount,
                1
              );
              this.lectures[i].lastDownloadDate2 =
                this.lectures[i].downloadCount > 0
                  ? moment(this.lectures[i].lastDownloadDate).fromNow()
                  : this.$t("lecOne.NoDownload");
            }

            this.$router
              .replace({ query: { stagesId: this.stageSelect } })
              .catch((err) => {})
              .finally(() => {});
          } else {
            this.lectures = [];
          }

          // console.log(this.lectures);
        })
        .catch((err) => {})
        .finally(() => {
          this.overlay = false;
        });
    },
    getLectuer() {
      this.overlay = true;
      if (
        this.folderId == "undefined" ||
        this.folderId == null ||
        !this.folderId
      ) {
        this.folderGuid = "00000000-0000-0000-0000-000000000000";
      } else {
        this.folderGuid = this.folderId;
      }
      this.axios
        .get(
          `Lectures/Get?SubjectGuid=${this.id}&FolderGuid=${this.folderGuid}&GroupByFolder=true&StagesGuid=${this.$store.state.userData.stageGuid}`
        )
        .then((res) => {
          this.lectures = res.data.data.files;
          this.folders = res.data.data.folders;
          this.currentFolder = res.data.data.currentFolder;
          for (let i = 0; i < this.lectures.length; i++) {
            this.lectures[i].filetype = this.get_url_extension(
              this.lectures[i].fileNamex
            );
            this.lectures[i].downloadProgress = 0;
          }
          // console.log(this.folders);
        })
        .catch((err) => {})
        .finally(() => {
          this.overlay = false;
        });
    },
    back() {
      this.$router.go(-1);
    },
    nFormatter(num, digits) {
      const si = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "k" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "G" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "P" },
        { value: 1e18, symbol: "E" },
      ];
      const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
      let i;
      for (i = si.length - 1; i > 0; i--) {
        if (num >= si[i].value) {
          break;
        }
      }
      return (
        (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol
      );
    },
  },

  created() {
    this.currentView =
      localStorage.getItem("lectureView") != "large" &&
      localStorage.getItem("lectureView") != "details"
        ? "large"
        : localStorage.getItem("lectureView");

    this.axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
    this.getLecTech();

    if (this.$route.query.stagesId) {
      if (Array.isArray(this.$route.query.stagesId)) {
        this.stageSelect = this.$route.query.stagesId;
      } else {
        this.stageSelect = [this.$route.query.stagesId];
      }
    }
    if (this.isInRole(2)) {
      this.upDataLecTeacher();
    } else {
      this.getLectuer();
    }
  },
};
</script>

<style lang="scss" scoped>
.posDate {
  position: absolute;
  top: 0;
  left: 0;
}
.posDate2 {
  position: absolute;
  top: 0;
  right: 0;
}
.chunk-panel {
  width: 505px;
  height: 165px;
  overflow-y: auto;
  padding: 18px;
  margin-top: 40px;
  background-color: rgba(191, 191, 191, 0.15);
}

.segment-size,
.loaded-size {
  margin-left: 3px;
}

.note {
  display: block;
  font-size: 10pt;
  color: #484848;
  margin-left: 9px;
}
.note > span {
  font-weight: 700;
}
.fileUpload {
  border: 1px solid #9e9e9e;
  padding: 10px;
  border-radius: 5px;
}
.zin {
  z-index: 1;
}
.posLeft {
  position: absolute;
  left: 10px;
}
.posRight {
  position: absolute;
  right: 10px;
}
.v-progress-linear {
  display: block;
  width: 100px;
  border-radius: 5px;
}
</style>
<style lang="scss" >
.v-slide-group__next,
.v-slide-group__prev {
  min-width: 12px !important;
  flex: 0 1 15px !important;
}
.select-all-cards .v-input__control {
  height: 25px;
}
</style>